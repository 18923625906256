"use strict";
function Menu(e) {
    if (e || e.element) {
        var n = e.element || !1
          , t = e.openClass || "menu-open";
        this.open = function() {
            n.classList.add(t)
        }
        ,
        this.close = function() {
            n.classList.remove(t)
        }
        ,
        this.toogle = function() {
            n.classList.toogle(t)
        }
    } else
        console.error("No element passed.")
}
var menuElement = document.getElementById("menu")
  , menu = new Menu({
    element: menuElement,
    openClass: "sidebar-open"
})
  , menuOpenButton = document.getElementById("menu-open-button")
  , menuCloseButton = document.getElementById("menu-close-button")
  , sidebar = document.getElementById("sidebar");
sidebar.addEventListener("click", function(e) {
    e.stopPropagation()
}),
menuOpenButton.addEventListener("click", function(e) {
    e.stopPropagation(),
    menu.open()
}),
menuCloseButton.addEventListener("click", function() {
    menu.close()
}),
document.addEventListener("click", function() {
    menu.close()
});
